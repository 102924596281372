import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useRouter } from '@bibliocommons/utils-routing';
import Context from '@bibliocommons/context';
import { TOGGLZ_FEATURES } from '@bibliocommons/constants-feature-togglz';
import { Button } from '@bibliocommons/base-buttons';
import { Dropdown, DropdownMenuItem, DropdownMenuDivider } from '@bibliocommons/base-dropdown';
import ToggleSwitch from '@bibliocommons/toggle-switch';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import ModalActions from 'app/actions/ModalActions';
import ModalContainer from 'app/components/shared/Modal/ModalContainer';
import EventsPdfDownloadModal from 'app/components/events/EventsBrochure/EventsPdfDownloadModal';

import './EventsViewOptions.scss';

const BROCHURE_MODAL_KEY = 'events-brochure-download';

export default function EventsViewOptions({
  eventsRSSLink,
  hasFeaturedEvents,
  hideFeaturedEventsToggle,
  pagination,
  showPdfOption
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { mobileApp, features } = useContext(Context);
  const currentLibrary = useSelector(selectCurrentLibrary);
  const isFeaturedToggleActive = router.query.featured === 'true';
  const isCancelledToggleActive = router.query.cancelled === 'false';

  function closeBrochureDownloadModal() {
    dispatch(ModalActions.closeModal(BROCHURE_MODAL_KEY));
  }

  function renderFeaturedToggle() {
    function handleFeaturedToggle() {
      router.push({
        query: {
          ...router.query,
          page: undefined,
          featured: isFeaturedToggleActive ? undefined : 'true'
        }
      });
    }

    if (hasFeaturedEvents) {
      return (
        <ToggleSwitch
          id="feature-events-toggle"
          className="feature-events-toggle"
          active={isFeaturedToggleActive}
          activeText={<FormattedMessage id="featured_events_only" />}
          inactiveText={<FormattedMessage id="featured_events_only" />}
          handleClick={handleFeaturedToggle}
        />
      );
    }
    return null;
  }

  function renderAdditionalOptions() {
    function handleCancelledToggle() {
      router.push({
        query: {
          ...router.query,
          page: undefined,
          cancelled: isCancelledToggleActive ? undefined : 'false'
        }
      });
    }

    function handlePrint() {
      window.print();
    }

    function handleRSSFeed() {
      const link = eventsRSSLink.replace(/&$/, '');
      window.open(link, '_blank').focus();
    }

    function renderLabel() {
      return <FormattedMessage id="options" />;
    }

    function openBrochureDownloadModal() {
      dispatch(ModalActions.openModal(BROCHURE_MODAL_KEY, { authRequired: false }));
    }

    function createAndDownloadPdf() {
      openBrochureDownloadModal();
    }

    function renderPdfMenuItem() {
      if (currentLibrary.getIn(['events', 'eventsPdfEnabled']) && features.get(TOGGLZ_FEATURES.ENABLE_EVENTS_PDF)) {
        return (
          <>
            <DropdownMenuItem
              handleClick={createAndDownloadPdf}
              item={Immutable.Map({
                label: <FormattedMessage id="create_pdf" defaultMessage="Create PDF" />,
                value: 'create_pdf'
              })}
            />
            <DropdownMenuDivider />
          </>
        );
      }
      return null;
    }

    return (
      <Dropdown
        buttonTag={Button}
        dataKey="events-view-options"
        buttonClassName="dropdown-button"
        renderLabel={renderLabel}
        right
      >
        {!hideFeaturedEventsToggle && (
          <>
            <DropdownMenuItem closeOnClick={false}>
              <ToggleSwitch
                id="cancelled-events-toggle"
                active={isCancelledToggleActive}
                handleClick={handleCancelledToggle}
                activeText={<FormattedMessage id="hide_cancelled_events" />}
                inactiveText={<FormattedMessage id="hide_cancelled_events" />}
              />
            </DropdownMenuItem>
            <DropdownMenuDivider />
          </>
        )}
        {showPdfOption && renderPdfMenuItem()}
        {!mobileApp ? (
          <DropdownMenuItem
            item={Immutable.Map({ label: <FormattedMessage id="print" />, value: 'print' })}
            handleClick={handlePrint}
          />
        ) : null}
        {eventsRSSLink && (
          <DropdownMenuItem
            item={Immutable.Map({ label: <FormattedMessage id="rss_feed" />, value: 'rss_feed' })}
            handleClick={handleRSSFeed}
          />
        )}
      </Dropdown>
    );
  }

  return (
    <div className="cp-events-view-options">
      {renderFeaturedToggle()}
      {renderAdditionalOptions()}
      <ModalContainer modalKey={BROCHURE_MODAL_KEY} handleClose={closeBrochureDownloadModal}>
        <EventsPdfDownloadModal pagination={pagination} modalKey={BROCHURE_MODAL_KEY} />
      </ModalContainer>
    </div>
  );
}

EventsViewOptions.propTypes = {
  eventsRSSLink: PropTypes.string,
  hasFeaturedEvents: PropTypes.bool,
  hideFeaturedEventsToggle: PropTypes.bool,
  pagination: ImmutablePropTypes.map,
  showPdfOption: PropTypes.bool
};
